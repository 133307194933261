import React from "react";
import { Layout } from "../components/Layout";
import SEO from "../components/SEO";
import { graphql } from "gatsby";

const App = ({ data }) => (
  <Layout>
    <SEO title="HOME" />
    <div className="container">
      <div className="home-intro">
        Hi, my name is Igor and I like&nbsp;
        <span className="underline">making things</span>
      </div>
      <div className="home-content">
        <div
          style={{
            backgroundImage: "url(" + data.homeImageUrl.publicURL + ")",
            backgroundSize: "cover",
            mixBlendMode: "screen"
          }}
        >
          <div
            style={{
              backgroundColor: "black",
              width: "100%",
              height: 50,
              marginBottom: 50
            }}
          ></div>
          <div
            style={{
              backgroundColor: "black",
              width: "100%",
              height: 50,
              marginBottom: 50
            }}
          ></div>
          <div
            style={{
              backgroundColor: "black",
              width: "100%",
              height: 50,
              marginBottom: 50
            }}
          ></div>
        </div>
      </div>
    </div>
  </Layout>
);

export default App;

export const query = graphql`
  query getHomeImagePath {
    homeImageUrl: file(
      name: { eq: "urbanscape-1" }
      sourceInstanceName: { eq: "images" }
    ) {
      name
      publicURL
    }
    homeImageUrl1: file(
      name: { eq: "rain" }
      sourceInstanceName: { eq: "images" }
    ) {
      name
      publicURL
    }
  }
`;
